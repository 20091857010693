<template>
  <div v-show="!stripeID">
    <div class="h-screen flex w-full items-center justify-center" id="page-login">
    <div class="sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-1/2 xxl:w-3/4 sm:m-0 m-4">
      <vx-card class="CardLogin" >
        <br>
          <img
                  class="logoCustomLoginAccountCreated"
                  src="https://flattlo-app.s3.amazonaws.com/base-stock-images/flattlo3.png"
                  alt="Flattlo"
                />
                
        <div class="mt-6 mb-4">
            <p class="welcomeMsj">{{getFirstName}} ¡Tu cuenta fue creada exitosamente! </p>
            <p class="welcomeMsjParagraph">📭 Encontraras una notificación  en la bandeja de tu correo electrónico. </p>
        </div>
        <vs-button
                    class="large btnAccountCreated vs-con-loading__container mt-8 mb-4"
                    @click="gotoLogin"
                    color="warning"
                    ref="loadableButton"
                    id="button-with-loading"
                    text-color="#2f2f2f"
                  >Ir a login</vs-button>
      </vx-card>
    </div>
  </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
         backgroundLoading: '#22c16b',
         backgroundLoadingFail: '#e85151',
         
    };
  },
  methods: {
    openLoadingBackgroundSuccess() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: "rgb(255, 255, 255)"
      });
      setTimeout(() => {
        this.$vs.loading.close();
      }, 3000);
    },
    
    openLoadingBackgroundFail() {
      this.$vs.loading({
        background: this.backgroundLoadingFail,
        color: "rgb(255, 255, 255)"
      });
      setTimeout(() => {
          this.$router.push("/create-account");
          this.$vs.loading.close();
          this.$vs.notify({
            time:8000,
            title: '🤦‍♂️ Lo sentimos, tu cuenta no fue creada, intenta de nuevo.',
            text: 'Intenta con otro medio de pago o tarjeta. ',
            color: "danger"
          });
      }, 1000);
    },
    gotoLogin(){
      this.$router.push("/login");
    },

    activateUser(){
      this.$apollo.mutate({
        //Query
        mutation: gql`mutation(
          $userID:String!
          ){
            activateUser(
              userID:$userID
            ){
              _id
            }
          }`,
          variables:{
             userID: localStorage.userID
          }
      }).then(() =>{
          this.$vs.notify({
              time:15000,
              title: "✔✔ Tu cuenta fue creada y activada",
              text: "❤ Flattlo te da la bienvenida 🙌🎉🎉✨",
              color: "success"
            });

      }).catch((err) =>{
        console.log(err)
      })
    }
  },
  computed: {
    getStripeID() {
      return this.$route.params.Id;
    },
    stripeID() {
      if (this.getStripeID.length == 64) {
        return true,
        this.activateUser(),
        this.openLoadingBackgroundSuccess()
      }
      this.openLoadingBackgroundFail()
      return false;
    },
    getFirstName(){
      return localStorage.user_name
    }
  },
  mounted(){
      this.$vs.loading({
        background: this.backgroundLoading,
        color: "rgb(255, 255, 255)"
      });
      setTimeout(() => {
        this.$vs.loading.close();
      }, 3000);

  }
};
</script>

<style lang="scss" >
.CardLogin{
  background-color: #0d47a1;
}
body {
  background-image: url(https://flattlo-app.s3.amazonaws.com/base-stock-images/background_flattlo_4.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #0d47a1
}

.logoCustomLoginAccountCreated {
    display: block;
    margin: 0 auto;
    width: 32%;
    margin-top: 2vh;
  }
  .welcomeMsj{
      color: #FFF;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      margin: 5px;
  }
  .welcomeMsjParagraph{
      color: #FFF;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      margin: 5px;
  }
  .btnAccountCreated {
    width: 32%;
    display: block;
    margin: 0 auto;
    color: #000;
    font-weight: 500;
    border-radius: 22px;
  }
</style>